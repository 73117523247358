import { IonButton, IonContent, IonPage } from '@ionic/react';
import React from 'react';
import './Landing.css';

const LandingPage: React.FC = () => {
  console.log(process.env);
  return (
    <IonPage>
      <IonContent className="l-landing ion-padding">
        <div className="l-landing__content">
          <header>
            <h1>Ceci app logo</h1>
            <p>tag line</p>
          </header>
          <div className="l-landing__options">
            <IonButton expand="full" href="/sign-up">
              Criar conta
            </IonButton>
            <IonButton expand="full" href="/sign-in">
              Entrar
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LandingPage;
