import { initializeApp } from '@firebase/app';
import { isPlatform } from '@ionic/react';
import { FirebaseAuthentication, GetIdTokenOptions, User } from '@capacitor-firebase/authentication';
import { Observable, ReplaySubject } from 'rxjs';

export class FirebaseService {
  private currentUserSubject = new ReplaySubject<User | null>(1);

  constructor(
  ) {
    FirebaseAuthentication.removeAllListeners().then(() => {
      FirebaseAuthentication.addListener('authStateChange', (change) => {
        this.currentUserSubject.next(change.user);
      });
    });
    // Only needed to support dev livereload.
    FirebaseAuthentication.getCurrentUser().then((result) => {
      this.currentUserSubject.next(result.user);
    });
  }

  public async initialize(): Promise<void> {
    if (isPlatform('capacitor')) {
      return;
    }
    initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_apiKey,
      authDomain: process.env.REACT_APP_FIREBASE_authDomain,
      databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
      projectId: process.env.REACT_APP_FIREBASE_projectId,
      storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
      messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
      appId: process.env.REACT_APP_FIREBASE_appId,
      measurementId: process.env.REACT_APP_FIREBASE_measurementId,
    });
  }

  public async getIdToken(options?: GetIdTokenOptions): Promise<string> {
    const result = await FirebaseAuthentication.getIdToken(options);
    return result.token;
  }

  public get currentUser(): Observable<User | null> {
    return this.currentUserSubject.asObservable();
  }

  public async signInWithGoogle(): Promise<void> {
    await FirebaseAuthentication.signInWithGoogle();
  }

  public async signInWithEmailAndPassword({ email, password }: { email: string; password: string }): Promise<void> {
    await FirebaseAuthentication.signInWithEmailAndPassword({
      email,
      password,
    });
  }

  public async createUserWithEmailAndPassword({ email, password }: { email: string; password: string }): Promise<User | null> {
    const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
      email,
      password,
    });
    return result.user;
  }

  public async signOut(): Promise<void> {
    await FirebaseAuthentication.signOut();
  }
}
