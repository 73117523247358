import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { add } from 'ionicons/icons';

import { RouteComponentProps } from "react-router";

const PatientListPage: React.FC<RouteComponentProps> = ({ match, history }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Pacientes</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={e => {
              e.preventDefault();
              history.push(`${match.url}/new`)
            }}>
              <IonIcon slot="icon-only" icon={add}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Paciente</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItemSliding>
            <IonItem routerLink={`${match.url}/patient/id`}>
              <IonLabel>Sliding Item with End Options</IonLabel>
            </IonItem>

            <IonItemOptions>
              <IonItemOption color="danger">Remover</IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default PatientListPage;
