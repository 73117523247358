import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import PatientPage from './pages/Patient';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import LandingPage from './pages/Landing';
import LoginPage from './pages/User/Login';
import SignUpPage from './pages/User/SignUp';
import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from './Hooks/AuthContext';

setupIonicReact();

const App: React.FC = () => {
  const [isLogged, setIsLogged] = useState(false);
  const { currentUser, loadingAuth } = useAuthContext();

  const checkUser = useCallback(
    async () => {
      if (currentUser) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    },
    [currentUser],
  );

  useEffect(() => {
    checkUser();
  }, [checkUser, currentUser]);

  return (
    <IonApp>
      {loadingAuth ? (<IonLoading isOpen={loadingAuth} message={'Loading...'} />) : (
        <IonReactRouter>
          {!loadingAuth && isLogged ? (
            <IonSplitPane contentId="main">
              <Menu />
              <IonRouterOutlet id="main">
                <Route path="/patients" render={(props) => <PatientPage {...props} />} />

                <Route path="/" exact={true}>
                  <Redirect to="/patients" />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
          ) : (
            <IonRouterOutlet id="new">
              <Route path="/" exact>
                <LandingPage />
              </Route>

              <Route path="/sign-in" exact>
                <LoginPage />
              </Route>

              <Route path="/sign-up" exact>
                <SignUpPage />
              </Route>

              <Route>
                <Redirect to="/" />
              </Route>
            </IonRouterOutlet>
          )}
        </IonReactRouter>
      )}
    </IonApp>
  );
};

export default App;
