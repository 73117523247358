import React, { useEffect, useMemo, useContext, useCallback } from 'react';
import { FirebaseService } from '../services/firebase';

export const AuthContext = React.createContext<any>(null);

const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const firebaseService = useMemo(() => new FirebaseService(), []);
  const [loadingAuth, setLoadingAuth] = React.useState(true);

  const [currentUser, setCurrentUser] = React.useState<any>(null);


  const rubens = useCallback(

    async () => {
      await firebaseService.initialize();
      firebaseService.currentUser.subscribe({
        next: (user: any) => {
          console.log(user)
          setCurrentUser(user);
          setLoadingAuth(false);
        }
      });

    },

    [firebaseService],
  )

  useEffect(() => {

    rubens();
  }, [firebaseService, rubens]);


  const exportUser = useMemo(() => {
    return {
      currentUser,
      firebaseService,
      loadingAuth
    };
  }, [currentUser, firebaseService, loadingAuth])

  return <AuthContext.Provider value={exportUser}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error(
      'useAuthContext must be used within an AuthContext',
    );
  }

  return context;
};

export default AuthProvider;
