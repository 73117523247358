import React, { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
} from '@ionic/react';
import './style-public.css';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from '../../Hooks';

const LoginPage: React.FC = () => {
  let history = useHistory();
  const { handleSubmit, control, formState: { errors }, setError, clearErrors } = useForm();
  const { firebaseService } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (data: any) => {
    setIsLoading(true);
    const { email, password } = data;

    clearErrors('generic');
    await firebaseService.createUserWithEmailAndPassword({ email, password })
      .then((result: any) => {
        history.push('/');
      })
      .catch((error: any) => {
        console.log(JSON.stringify(error));
        switch (error.code) {
          case 'auth/email-already-in-use':
            setError('email', { type: 'custom', message: 'E-mail já cadastrado' });
            break;

          default:
            setError('generic', { type: 'custom', message: 'E-mail ou senha inválidos' });
            break;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <IonPage>
      <IonContent className="l-page ion-padding">
        <div className="l-page__content">
          <form onSubmit={handleSubmit(data => handleLogin(data))} className="l-page__form">
            Crie uma conta
            <IonList>
              {errors.generic && <IonItem lines='none'>
                <IonNote color="danger">{errors.generic.message}</IonNote>
              </IonItem>}
              <IonItem>
                <IonLabel position="floating">E-mail</IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput disabled={isLoading} onIonChange={onChange} type="email" />
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: "Obrigatório",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "e-mail inválido"
                    }
                  }}
                />
                {errors.email && <IonNote slot="error" color="danger">{errors.email.message}</IonNote>}
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Senha</IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput disabled={isLoading} onIonChange={onChange} type="password" />
                  )}
                  control={control}
                  name="password"
                  rules={{
                    required: "Obrigatório",
                    minLength: 6,
                  }}
                />
                {errors.password && <IonNote slot="error" color="danger">{errors.password.message}</IonNote>}
              </IonItem>
              <IonItem lines="none">
                <IonButton fill="clear">
                  Recuperar a senha
                </IonButton>
              </IonItem>
            </IonList>

            <div className="l-page__form_footer">
              <IonButton fill="clear">
                Criar conta
              </IonButton>
              <IonButton disabled={isLoading} type='submit'>
                Entrar
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
