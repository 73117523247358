import React from 'react';
import { IonPage, IonRouterOutlet } from "@ionic/react";
import { RouteComponentProps, Route } from "react-router";
import PatientNewPage from "./NewPage";
import PatientDetailPage from "./DetailPage";
import PatientListPage from "./ListPage";

const PatientPage: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet id="patient">
      <Route exact path={match.url} component={PatientListPage} />
      <Route path={`${match.url}/new`} component={PatientNewPage} />
      <Route path={`${match.url}/patient/:id`} component={PatientDetailPage} />
    </IonRouterOutlet>
  );
};

export default PatientPage;
