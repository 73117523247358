import React from 'react';
import AuthProvider, { useAuthContext } from './AuthContext';

interface ProvidersProps { }

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export default Providers;

export {
  useAuthContext,
}
